<template>
  <v-avatar color="#F0533D" class="button-style">
    <v-icon dark color="white">mdi-delete</v-icon>
  </v-avatar>
</template>

<script>
export default {
  name: 'DeleteIcon'
}
</script>

<style scoped>

</style>
