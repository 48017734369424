
import {defineComponent} from 'vue';
import PralineCard from "@/components/PralineCard.vue";

export default defineComponent({

  name: 'HomeView',
  components: {PralineCard},
  data: () => ({
    width: window.innerWidth/3,
    cols: 6,
  }),
  methods: {
    resize(e) {
      if (window.innerWidth > 500){
        this.width = window.innerWidth/3
        this.cols = 6
      }else{
        this.width = window.innerWidth - 50
        this.cols = 12
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.resize);
    window.dispatchEvent(new Event("resize"))
  },
  unmounted() {
    window.removeEventListener("resize", this.resize);
  },
  computed: {
    imgWidth() {
      return {
        width: `${this.width}px`
      }
    },
  },
});
