<template>
  <v-avatar color="#FFE600" class="button-style">
    <v-icon dark color="white">mdi-pencil</v-icon>
  </v-avatar>
</template>

<script>
export default {
  name: 'EditIcon'
}
</script>

<style scoped>

</style>
