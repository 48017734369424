<template>
  <v-container align="center">
   <v-row  align="center" class="d-flex flex-column row">
      <div id="app">
        <h1_titel align="center">{{ this.titel }}</h1_titel>
        <ul :style="gridStyle" class="card-list">
          <li v-for="(praline, index) in this.pralines" class="card-item">
            <PralineCard v-bind:praline="praline"/>
          </li>
          <li align="left" v-for="(assortiment, index) in this.assortiment" class="card-item">
            <PralineCardAssortiment v-bind:assortiment="assortiment"/>
          </li>
        </ul>
      </div>
    </v-row>
    <v-row align="center">
      <v-col>
        <p class="text-block">{{this.text}}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PralineCard from "@/components/PralineCard.vue";
import PralineCardAssortiment from "@/components/PralineCardAssortiment.vue";
import NormalButton from "@/components/NormalButton.vue";
import router from "@/router";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";

export default {
  name: 'ListPage',
  components: {DeleteIcon, PralineCard,PralineCardAssortiment,NormalButton},
  props: {
    titel : {
      type: String,
      default: '',
      color: '#000000'
    },
    pralines: {
      type: Object,
      default: [{naam:"Test", beschrijving:"test praline", image: "in.png"}]
    },
    assortiment: {
      type: Object,
      default: [{naam:"Test", beschrijving:"test praline"}]
    },
    text: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    isAdmin: false,
    url: '',
    numberOfColumns: Math.floor(window.innerWidth / 400),
  }),
  // beforeRouteUpdate(to, from) {
  //   const doos = Number(to.params.doos)
  //   if (doos !== Number(from.params.doos)) {
  //     this.changeUrl(doos);
  //   }
  // },
  methods: {
    router() {
      return router
    },
    resize(e) {
      this.numberOfColumns = Math.floor(window.innerWidth / 400)
    },
    changeUrl(doos) {
      this.url = window.location.host + '/pralines/' + doos.toString()
    },
},
  computed: {
    gridStyle() {
      return {
        gridTemplateColumns: `repeat(${this.numberOfColumns}, minmax(300px, 1fr))`
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.resize);
  },
  unmounted() {
    window.removeEventListener("resize", this.resize);
  },
  async beforeCreate(){
    this.isAdmin = false;
    this.url = window.location.href;
  },
}
</script>

<style scoped>
ul {
  list-style-type: none;
}
.card-list {
  display: grid;
  grid-gap: 1em;
}

.card-item {
  padding: 2em;
}

.row {
  padding: 10px;
}

#app {
  background: #ffefd8;
  border-radius: 4px;
  padding: 20px;
  transition: all 0.2s;
}
</style>
